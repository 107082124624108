<template>
    <!-- CHI SIAMO -->
     <div class="container pt-5">
         <div class="row pt-5">
             <!-- Testi a sinistra-->
             <div class="col-md-5 align-self-center mb-5 pt-5 bb-padding">
                 <p class="bb-txt-sec-name mb-4">{{ $t('WHO_WE_ARE_TITLE') }}</p>
                 <p class="bb-txt-sec-title mb-4" >{{ $t('WHO_WE_ARE_SUBTITLE_1') }}</p>
                 <p class="bb-txt-sec-subtitle">{{ $t('WHO_WE_ARE_SUBTITLE_2') }}</p>
             </div>
 
             <!-- Immagini a destra-->
             <div class="col-md-7 align-self-center mb-5 bb-padding">
                <img class="img-fluid" src="../../assets/images/chi-siamo.png" alt="" >
             </div>
         </div>
         <div class="row">
            <div class="col-sm-12">
                <h3 class="bb-txt-sec-title text-light text-center mb-5 mt-5">{{ $t('WHO_WE_ARE_POINT') }}</h3>
            </div>
        </div>
        <div class="row text-center justify-content-center bb-padding pb-5">
            <!-- professionalism-->
            <div class="col-lg-4 col-md-6 p-4">
                <div class="card bb-card-background h-100">
                    <div class="card-body text-center">
                        <img class="mb-3 mt-3" src="../../assets/images/icons/icon_professionalism.svg" alt="" width="60" height="70">
                        <h6 class="card-title text-center pb-3">
                            <span class="bb-text-green ">{{ $t('WHO_WE_ARE_PROF') }}</span>
                        </h6>
                        <p class="card-text text-light  bb-text-limited">
                            {{ $t('WHO_WE_ARE_TEMP') }}
                        </p>
                    </div>
                </div>
            </div>
            <!-- expertise-->
            <div class="col-lg-4 col-md-6 p-4">
                <div class="card bb-card-background h-100">
                    <div class="card-body text-center bb-card">
                        <img class="mb-3 mt-3" src="../../assets/images/icons/icon_experties.svg" alt="" width="60" height="70">
                        <h6 class="card-title text-center pb-3">
                            <span class="bb-text-green ">{{ $t('WHO_WE_ARE_COMP') }}</span>
                        </h6>
                        <p class="card-text text-light  bb-text-limited mt-2 mb-4">
                            {{ $t('WHO_WE_ARE_SOLUTION') }}
                        </p>
                    </div>
                </div>
            </div>
            <!-- Co-investimenti -->
            <div class="col-lg-4 col-md-6 p-4">
                <div class="card bb-card-background h-100">
                    <div class="card-body text-center">
                        <img class="mb-3 mt-3"  src="../../assets/images/icons/icon_co_investments.svg" alt="" width="60" height="70">
                        <h6 class="card-title text-center pb-3">
                            <span class="bb-text-green ">{{ $t('WHO_WE_ARE_COINV') }}</span>
                        </h6>
                        <p class="card-text text-light  bb-text-limited mt-2 mb-4">
                            {{ $t('WHO_WE_ARE_SECURITY') }}
                        </p>
                    </div>
                </div>
            </div>
            <!-- Low costs-->
            <div class="col-lg-4 col-md-6 p-4">
                <div class="card bb-card-background h-100">
                    <div class="card-body text-center">
                        <img class="mb-3 mt-3" src="../../assets/images/icons/icon_low_costs.svg" alt="" width="60" height="70">
                        <h6 class="card-title text-center pb-3">
                            <span class="bb-text-green ">{{ $t('WHO_WE_ARE_COST_CONT') }}</span>
                        </h6>
                        <p class="card-text text-light  bb-text-limited mt-2 mb-4">
                            {{ $t('WHO_WE_ARE_WORK') }}
                        </p>
                    </div>
                </div>
            </div>
            <!-- Strategy-->
            <div class="col-lg-4 col-md-6 p-4">
                <div class="card bb-card-background h-100">
                    <div class="card-body text-center">
                        <img class="mb-3 mt-3" src="../../assets/images/icons/icon_strategy.svg" alt="" width="60" height="70">
                        <h6 class="card-title text-center pb-3">
                            <span class="bb-text-green ">{{ $t('WHO_WE_ARE_STRAT') }}</span>
                        </h6>
                        <p class="card-text text-light  bb-text-limited mt-2 mb-4">
                            {{ $t('WHO_WE_ARE_COOP_LONG') }}
                        </p>
                    </div>
                </div>
            </div>
            <!-- Cooperation -->
            <div class="col-lg-4 col-md-6 p-4">
                <div class="card bb-card-background h-100">
                    <div class="card-body text-center">
                        <img class="mb-3 mt-3" src="../../assets/images/icons/icon_cooperation.svg" alt="" width="60" height="70">
                        <h6 class="card-title text-center pb-3">
                            <span class="bb-text-green ">{{ $t('WHO_WE_ARE_COOP') }}</span>
                        </h6>
                        <p class="card-text text-light  bb-text-limited mt-2 mb-4">
                            {{ $t('WHO_WE_ARE_BUILD') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
     </div>
    <!-- PUNTI DI FORZA -->

</template>
  
<script setup lang="ts">
</script>
  
<style scoped lang="scss">

.bb-padding-custom{
    padding: 0rem 9rem 9rem 9rem;
}

.card-body{
    min-height: 20rem;
}


.bb-text-green{
    color: #37D599;
    font-family: "Inter", sans-serif;
    font-weight: 900;
    font-size: 18px;
}
.bb-text-limited {
    max-width: 200px;
    margin: 0 auto;
    font-family: "Inter", sans-serif;
    font-weight: 200;
    font-size: 15px;
}

.bb-card-background {
    background: #032433 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #00000080;
    border-radius: 40px;
    opacity: 1;
}

@media only screen  and (min-width:426px) and (max-width:1024px) {
    .bb-padding-custom{
        padding: 0rem 3rem 3rem 3rem;
    }
}

@media only screen and (min-width:1300px){
    .bb-img-padding{
        padding: 5rem 13rem 5rem 15rem
    }
}

@media only screen  and (max-width:425px) {
    .bb-padding-custom{
        padding: 0rem 2rem 2rem 2rem;
    }
}

</style>
