import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/icons/bb-logo.svg'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "navbar fixed-top navbar-expand-lg navbar-light bb-navbar px-3 px-sm-5" }
const _hoisted_3 = { class: "container-fluid mt-3 mb-3" }
const _hoisted_4 = {
  class: "collapse navbar-collapse justify-content-end",
  id: "navbarSupportedContent"
}
const _hoisted_5 = { class: "order-1 order-md-2" }
const _hoisted_6 = { class: "navbar-nav mb-2 mb-lg-0 self-align-end order-2 order-md-1" }
const _hoisted_7 = { class: "nav-item bb-nav-item" }
const _hoisted_8 = { class: "nav-item bb-nav-item" }
const _hoisted_9 = { class: "nav-item bb-nav-item" }
const _hoisted_10 = {
  "data-bs-toggle": "collapse",
  "data-bs-target": ".navbar-collapse.show"
}
const _hoisted_11 = { class: "nav-item bb-nav-item" }

import { useRouter } from "vue-router";
import LanguageComponent from "../elements/LanguageComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'NavbarComponent',
  setup(__props) {

const router = useRouter();

const scrollToSection = (sectionId: string) => {
  if(router.currentRoute.value.name !== 'home') {
          router.push({ name: 'home', query: { section: sectionId } });
  } else {
      const element = document.getElementById(sectionId);
      if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
      }
  }
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("nav", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            class: "navbar-brand bb-ml",
            to: { name: 'home' }
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                width: "70",
                height: "55"
              }, null, -1)
            ])),
            _: 1
          }),
          _cache[4] || (_cache[4] = _createElementVNode("button", {
            class: "navbar-toggler custom-toggler",
            type: "button",
            "data-bs-toggle": "collapse",
            "data-bs-target": "#navbarSupportedContent",
            "aria-controls": "navbarSupportedContent",
            "aria-expanded": "false",
            "aria-label": "Toggle navigation"
          }, [
            _createElementVNode("span", { class: "navbar-toggler-icon" })
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(LanguageComponent)
            ]),
            _createElementVNode("ul", _hoisted_6, [
              _createElementVNode("li", _hoisted_7, [
                _createElementVNode("a", {
                  class: "nav-link",
                  "data-bs-toggle": "collapse",
                  "data-bs-target": ".navbar-collapse.show",
                  "aria-current": "page",
                  href: "#",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (scrollToSection('who-we-are')), ["prevent"]))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('NAVBAR_WHO_WE_ARE')), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_8, [
                _createElementVNode("a", {
                  class: "nav-link",
                  "data-bs-toggle": "collapse",
                  "data-bs-target": ".navbar-collapse.show",
                  href: "#",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (scrollToSection('what-we-do')), ["prevent"]))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('NAVBAR_WHAT_WE_DO')), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_9, [
                _createVNode(_component_router_link, {
                  class: "nav-link",
                  to: { name: 'workWithUs' }
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('NAVBAR_WORK_WITH_US')), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("li", _hoisted_11, [
                _createElementVNode("a", {
                  class: "nav-link",
                  href: "#",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (scrollToSection('contacts')), ["prevent"])),
                  "data-bs-toggle": "collapse",
                  "data-bs-target": ".navbar-collapse.show"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('NAVBAR_CONTACTS')), 1)
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})