<template>
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-sm-12 align-self-left mb-5 mt-5 bb-padding">
        <p class="bb-txt-sec-name mb-4">{{ $t('CONTACTS_TITLE') }}</p>
        <p class="bb-txt-sec-title mb-4" >{{ $t('CONTACTS_FORM') }}</p>
        <p class="bb-txt-sec-subtitle">{{ $t('CONTACTS_FORM_INF') }}</p>
    </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-lg-6 bb-padding-box">
        <div class="map-container ratio ratio-16x9 ">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2760.595362111384!2d8.94979751555781!3d46.00597897911232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4784d9a8edc4e2af%3A0xa1d91a72e34b3ed8!2sVia%20Serafino%20Balestra%2010%2C%206900%20Lugano%2C%20Svizzera!5e0!3m2!1sit!2sit!4v1698165248000!5m2!1sit!2sit" 
            allowfullscreen="" 
            loading="lazy" 
            class="rounded">
          </iframe>
        </div>
      </div>

      <div class="col-sm-12 col-lg-6 bb-padding-box-reverse">
        <div class="card  bg-info">
          <h3 class="bb-form-title mb-4">{{ $t('CONTACTS_INSERT_INFO') }}</h3>
          <form id="form" @submit.prevent="submitForm">
            <div class="form-group mb-3">
              <label for="name" class="bb-form-txt">{{ $t('CONTACTS_FULLNAME') }}</label>
              <input type="text" class="form-control" id="name" placeholder="Nome Cognome" v-model="formData.name">
            </div>
            <div class="form-group mb-3">
              <label for="email" class="bb-form-txt">{{ $t('CONTACTS_EMAIL') }}</label>
              <input type="email" class="form-control" id="email" placeholder="Email" v-model="formData.email">
            </div>
            <div class="form-group mb-3">
              <label for="note" class="bb-form-txt">{{ $t('CONTACTS_NOTE') }}</label>
              <textarea class="form-control" id="note" rows="3" placeholder="Inserisci qui il motivo per cui ci stai contattando" v-model="formData.note"></textarea>
            </div>
            <div class="form-check mb-4 mt-4">
              <input type="checkbox" class="form-check-input" id="privacyCheck" v-model="formData.privacyAccepted">
              <label class="form-check-label" for="privacyCheck">
                {{ $t('CONTACTS_CONFIRM_PRIVACY') }}
              </label>
            </div>
            <div class="text-center  mb-4 mt-4">
              <button type="submit" class="bb-btn-submit" :disabled="!isFormValid">{{ $t('CONTACTS_CONTACT') }}</button>
            </div>

            <div class="alert alert-success text-center" role="alert" v-show="checkMailSuccess">
              <strong>{{ $t('TXT_MAIL_SUCCESS_1') }}</strong> {{ $t('TXT_MAIL_SUCCESS_2') }}
          </div>

          <div class="alert alert-danger text-center" role="alert" v-show="checkMailError">
              <strong>{{ $t('TXT_MAIL_ERROR_1') }}</strong> {{ $t('TXT_MAIL_ERROR_2') }}
          </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row pt-5">
      <hr class="bb-hr">
      <div class="col-sm-12 mb-5 mt-3 bb-padding">
        <h4 class="bb-txt-sec-title mb-4 ">{{ $t('CONTACTS_OUR_HEADQUARTERS') }}</h4>
        <div class="d-flex align-items-start mt-4 ">
          <div class="me-4 me-sm-5">
            <img src="../../assets/images/icons/sede icon.svg" alt="icona mappa" class="icon">
          </div>
          <div>
            <h5 class="bb-address-txt-title mb-3 mt-1">{{ $t('CONTACTS_LEGAL_HEADQUARTERS') }}</h5>
            <p class="bb-address-txt mb-0">{{ $t('CONTACTS_ADDRESS') }}</p>
            <p class="bb-address-txt mb-0">{{ $t('CONTACTS_NATION') }}</p>
          </div>
        </div>
      </div>
      <hr class="bb-hr mb-5">
    </div>

  </div>

</template>

<script setup>

import { ref, computed } from 'vue';
import emailjs from '@emailjs/browser';
const formData = ref({
  name: '',
  email: '',
  note: '',
  privacyAccepted: false
});

const checkMailSuccess = ref(false);
const checkMailError = ref(false);

const isFormValid = computed(() =>
      formData.value.name &&
      formData.value.email &&
      formData.value.note &&
      formData.value.privacyAccepted
    );

const submitForm = () => {
  checkMailError.value = false;
  checkMailSuccess.value = false;
  
  if (!formData.value.privacyAccepted) {
    alert('Devi accettare l\'informativa sulla Privacy.');
    return;
  }

  const serviceID = process.env.VUE_APP_EMAILJS_SERVICE_ID;
  const userID = process.env.VUE_APP_EMAILJS_USER_ID;

  // template mail inviata a beasy
  const templateMailBeasy = process.env.VUE_APP_EMAILJS_TEMPLATE_MAIL_BEASY;

  emailjs.send(serviceID,
               templateMailBeasy,
               {
                name: formData.value.name,
                email: formData.value.email,
                note: formData.value.note
                }, 
                userID)
    .then(() => {

      // template mail inviata al cliente
      const templateMailCustomer = process.env.VUE_APP_EMAILJS_TEMPLATE_MAIL_CUSTOMER;
      

      // Invia il primo template dopo il successo del secondo
      return emailjs.send(
        serviceID, 
        templateMailCustomer, 
        {
          name: formData.value.name,
          email: formData.value.email
        }, 
        userID);
    })
    .then(() => {
      checkMailSuccess.value =true;

      // Resetta i campi del form dopo l'invio
      formData.value.name = '';
      formData.value.email = '';
      formData.value.note = '';
      formData.value.privacyAccepted = false;
    })
    .catch(() => {
      console.log(checkMailError.value);
      checkMailError.value = true;
    });
};
</script>

<style scoped>

.alert{
  font-size: 14px !important;
  font-family: "Inter", sans-serif !important;
  border-radius: 30px !important;
}

.bb-address-txt-title{
    color: #5DC4EA;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 20px;
}

.bb-address-txt{
    color: #FFFFFF;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.bb-hr{
  color: #ffff;
}

.bb-btn-submit{
  width: 70%;
  height: 5ex;
  border: 0;
  text-transform: uppercase;
  color: #FFFFFF;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  background: #04364D 0% 0% no-repeat padding-box;
}

.bb-btn-submit:hover{
  width: 70%;
  height: 5ex;
  border: 0;
  text-transform: uppercase;
  color: #FFFFFF;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  background: #199fda 0% 0% no-repeat padding-box;
}

.bb-btn-submit:disabled{
  width: 70%;
    height: 5ex;
    border: 0;
    text-transform: uppercase;
    color: #6c6c6c;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    background: #4d4d4d;
}


.bb-form-title{
    color: #FFFFFF;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 24px;
    margin-top: 0.5rem;
}

.form-check-label{
  color: #FFFFFF;
  font-family: "Inter", sans-serif;
  font-weight: 200;
  font-size: 12px;
}

.bb-form-txt{
  color: #FFFFFF;
  font-family: "Inter", sans-serif;
  font-weight: 200;
  font-size: 16px;
  margin-bottom:0.5rem;
}

.form-control {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}

.bb-padding-box{
  padding: 3rem 5rem 3rem 10rem;
}

.bb-padding-box-reverse{
  padding: 3rem 10rem 3rem 5rem;
}
.card {
  border-radius: 30px;
  padding:3rem;
  background: transparent linear-gradient(146deg, #216F60 0%, #053E58 100%) 0% 0% no-repeat padding-box;
}

button {
  border-radius: 30px;
}

.map-container {
  border-radius: 30px;
  overflow: hidden;
  height: 99%;
  min-height: 99%;
}


@media only screen and (min-width:1025px) and (max-width:1440px) {
  .bb-padding-box{
    padding: 2rem 2rem 2rem 4rem;
  }

  .bb-padding-box-reverse{
    padding: 2rem 4rem 2rem 2rem;
  }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
  .bb-padding-box, .bb-padding-box-reverse{
      padding: 2rem 2rem 2rem 2rem !important;
    }

  .card {
    padding:1rem;
  }
}

@media only screen and (min-width:426px) and (max-width:768px) {

  .bb-padding-box, .bb-padding-box-reverse{
      padding: 2rem 2rem 2rem 2rem !important;
    }

  .card {
    padding:1rem;
  }

  .form-check-label{
    font-size: 11px;
  }

  .bb-form-txt, .form-control{
    font-size: 13px;
  }
  .bb-form-title{
    font-size: 20px;
  }
}

@media only screen and (max-width: 425px) {

  .alert{
    font-size: 12px !important;
  }

  .bb-padding-box{
      padding: 1rem 2rem 1rem 2rem;
    }
  
  .bb-padding-box-reverse{
    padding: 1rem 2rem 1rem 2rem;
  }

  .card {
    padding:1rem;
  }

  .form-check-label{
    font-size: 10px;
  }

  .bb-form-txt, .form-control{
    font-size: 12px;
  }

  .bb-form-title{
    font-size: 16px;
  }

  .bb-address-txt-title{
    font-size: 16px;
  }

  .bb-address-txt{
    font-size: 12px;
  }

}

</style>