import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeComponent from "../components/layout/HomeComponent.vue";
import WorkWithUsComponent from "../components/layout/WorkWithUsComponent.vue";
import TermsAndConditions from "@/components/layout/TermsAndConditions.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeComponent,
  },
  {
    path: "/careers",
    name: "workWithUs",
    component: WorkWithUsComponent,
  },
  {
    path: "/termsandconditions",
    name: "TermsAndConditions",
    component: TermsAndConditions,
  },
  {
    path: "/about",
    redirect: { path: "/", query: { section: "who-we-are" } },
  },
  {
    path: "/services",
    redirect: { path: "/", query: { section: "what-we-do" } },
  },
  {
    path: "/contacts",
    redirect: { path: "/", query: { section: "contacts" } },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/", 
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
