<template>
  <div class="container pb-5 pt-3">
    <div class="row">
      <div class="col-sm-12 pt-5 pb-5">
        <h1 class="text-center bb-txt-sec-title">{{ $t('TECHNOLOGIES') }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="lc-block">
          <div id="carouselLogos" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner px-5 px-sm-1">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-6 col-md-2 d-flex align-content-center flex-wrap" style="min-height: 130px;" >
                    <img class="img-fluid px-3 mb-3" src="@/assets/images/logos/tech/java.png" alt="">
                  </div>
                  <div class="col-6 col-md-2 d-flex align-content-center flex-wrap" style="min-height: 130px;" >
                    <img class="img-fluid px-3 mb-3" src="@/assets/images/logos/tech/vuejs.png" alt="">
                  </div>
                  <div class="col-6 col-md-2 d-flex align-content-center flex-wrap" style="min-height: 130px;" >
                    <img class="img-fluid px-3 mb-3" src="@/assets/images/logos/tech/liferay.png" alt="">
                  </div>
                  <div class="col-6 col-md-2  d-flex align-content-center flex-wrap" style="min-height: 130px;" >
                    <img class="img-fluid px-3  mb-3" src="@/assets/images/logos/tech/pl-sql.png" alt="">
                  </div>
                  <div class="col-6 col-md-2  d-flex align-content-center flex-wrap" style="min-height: 130px;" >
                    <img class="img-fluid px-3  mb-3" src="@/assets/images/logos/tech/Oracle.png" alt="">
                  </div>
                  <div class="col-6 col-md-2  d-flex align-content-center flex-wrap" style="min-height: 130px;" >
                    <img class="img-fluid px-3  mb-3" src="@/assets/images/logos/tech/Qlik.png" alt="">
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="row">
                  <div class="col-6 col-md-2  d-flex align-content-center flex-wrap" style="min-height: 130px;" >
                    <img class="img-fluid px-3 mb-3" src="@/assets/images/logos/tech/Power-BI.png" alt="">
                  </div>
                  <div class="col-6 col-md-2 d-flex align-content-center flex-wrap" style="min-height: 130px;" >
                    <img class="img-fluid px-3 mb-3" src="@/assets/images/logos/tech/hadoop.png" alt="">
                  </div>

                  <div class="col-6 col-md-2 d-flex align-content-center flex-wrap" style="min-height: 130px;" >
                    <img class="img-fluid px-3 mb-3" src="@/assets/images/logos/tech/groovy.png" alt="">
                  </div>
                  <div class="col-6 col-md-2  d-flex align-content-center flex-wrap" style="min-height: 130px;" >
                    <img class="img-fluid px-3 mb-3" src="@/assets/images/logos/tech/LTL.png" alt="">
                  </div>
                </div>
              </div>
            </div>
            <ol class="carousel-indicators list-unstyled position-relative">
              <li data-bs-target="#carouselLogos" data-bs-slide-to="0" class="active bg-dark carousel-control-prev-icon"></li>
              <li data-bs-target="#carouselLogos" data-bs-slide-to="1" class="bg-dark"></li>
            </ol>
            <div class="w-100 px-3 text-center">
              <a class="carousel-control-prev position-relative d-inline" href="#carouselLogos" data-bs-slide="prev">
              </a>
              <a class="carousel-control-next position-relative d-inline" href="#carouselLogos" data-bs-slide="next">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup lang="ts">
  </script>
  
  <style scoped>
  .carousel-item-next, .carousel-item-prev, .carousel-item.active, .carousel-item {
      min-height: 170px;
  }

  .bg-dark {
    background-color: rgb(249 249 249) !important;
  }

  </style>
  