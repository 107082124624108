<template>
    <div class="container-fluid fixed-bottom d-flex justify-content-center p-3" v-if="isVisible">
        <div class="row bb-background-coockies align-items-center px-2 py-3 gx-5 col-md-12 col-lg-10 col-11">
            <div class="">
                <div class="row">
                    <div class="col-sm-2 mb-3 mb-sm-0 align-self-center">
                        <img src="../../assets/images/icons/icon_cookies.svg" alt="">
                    </div>
                    <div class="col-sm-5 mb-3 mb-sm-0">
                        <p class="bb-txt-c">{{ $t('COOKIES_DESC') }}</p>
                    </div>
                    <div class="col-sm-5 align-self-center">
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <a class="btn btn-sm bb-background-button text-light text-uppercase fw-bold rounded-pill me-2 bb-txt-c" role="button" @click="acceptCookies">{{ $t('COOKIES_BTN_ACCEPT') }}</a>
                            <a class="btn btn-sm bb-background-button text-light text-uppercase fw-bold rounded-pill bb-txt-c" role="button" @click=goToTermsAndConditions>{{ $t('COOKIES_BTN_READ') }}</a>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';
import Cookies from 'js-cookie';

const isVisible = ref(false);
const router = useRouter();

const coockieConsentItem = 'cookieConsent';
const coockieConsentAccepted = 'accepted';

onMounted(() => {
    const coockiesAccepted = Cookies.get(coockieConsentItem);
    if(!coockiesAccepted || coockiesAccepted !== coockieConsentAccepted){
        isVisible.value = true;
    }
});

function acceptCookies(){
    Cookies.set(coockieConsentItem, coockieConsentAccepted);
    isVisible.value = false;
}

function goToTermsAndConditions(){
    router.push("termsandconditions");
}

</script>

<style scoped lang="scss">
.bb-background-coockies{
    background: #00121A;
    border-radius: 53px;
    opacity: 1;
}

.bb-background-button {
  background: #04364D 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
}

.btn-sm:hover{
    background: #199fda !important;
    border-radius: 25px;
    opacity: 1;
}

.btn-sm{
    border-radius: 25px;
    opacity: 1;
    padding-left: 2rem;
    padding-right: 2rem;
}


.bb-txt-c {
    color: #FFFFFF;
    font-family: "Inter", sans-serif;
    font-weight: 200;
    font-size: 16px;
}

@media only screen and (min-width:769px) and (max-width:1024px) {
    .bb-txt-c {
        font-size: 14px;
    }

}

@media only screen and (min-width:426px) and (max-width:768px) {
    .bb-txt-c {
        font-size: 11px;
    }

}

@media only screen and (max-width: 425px) {

    .bb-txt-c {
        font-size: 10px;
    }
}


</style>