<template>
    <div class="container-xxl bb-backgroud">
        <div class="row pt-5 pt-custom">
            <div class="col-sm-5 align-self-center bb-padding pb-4">
                <h4 class="bb-txt-sec-name mb-4  mt-5">{{ $t('WORK_WITH_US_TITLE') }}</h4>
                <h2 class="bb-txt-sec-title mb-4  ">{{ $t('WORK_WITH_US_CV') }}</h2>
                <p class="bb-txt-sec-subtitle  ">{{ $t('WORK_WITH_US_SUBTITLE') }}</p>
            </div>
            <div class="col-sm-7 align-self-center bb-padding">
                <img src="../../assets/images/work-with-us.png" alt="Lavoro" class="img-fluid rounded mx-auto d-block">
            </div>
        </div>
        <div class="row bb-white pt-5">
            <div class="col-sm-12 bb-padding">
                <h4 class="bb-txt-sec-name mb-4  txt-c">{{ $t('WORK_WITH_US_POS_OPEN') }}</h4>
                <h2 class="bb-txt-sec-title mb-5   txt-c">{{ $t('WORK_WITH_US_SEARCH') }}</h2>
            </div>
            <div class="col-sm-12 pb-5 bb-padding">
                <div class="bb-padding-custom">
                    <InrecruitingComponent />
                </div>
            </div>

        </div>
    </div>
</template>

<script setup lang="ts">
import InrecruitingComponent from '../elements/InrecruitingComponent.vue';

import { onMounted } from 'vue';

onMounted(() =>{
 window.scrollTo(0,0);
});


</script>

<style scoped lang="scss">

.pt-custom{
    padding-top: 10rem !important;
    padding-bottom: 3rem !important;
}

@media only screen and (max-width: 425px) {
    .pt-custom{
    padding-top: 7rem !important;
    padding-bottom: 3rem !important;
}


}

.bb-white{
    background: #fff;
}

.txt-c{
    color: #082d3e !important;
}

@media only screen and (min-width:426px) and (max-width:768px) {
    .img-fluid {
        max-width: 90% !important;
        height: auto !important;
    }
}

@media only screen and (max-width: 425px) {
    .img-fluid {
        max-width: 90% !important;
        height: auto !important;
    }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
    .img-fluid {
        max-width: 80% !important;
        height: auto !important;
    }
}

.img-fluid {
    max-width: 50% ;
    height: auto;
}

</style>
