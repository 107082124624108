import { createStore } from 'vuex'
import { i18n } from '@/main';

export default createStore({
  state: {
    availibleLocales : ['it', 'en'],
    locale: localStorage.getItem('locale') || 'it',
  },

  getters: {
    getLocale: (state)=> (lang: string) => {
      return state.availibleLocales.includes(lang) ? lang : state.locale;
    },
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
      i18n.global.locale = locale; // Aggiorna il locale di i18n
      localStorage.setItem('locale', locale);
    },
  },
  actions: {
  },
  modules: {
  }
})
