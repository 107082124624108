import en from "./en.json";
import it from "./it.json";

function loadLocaleMessages() {
  const messages = {
    en,
    it,
  };

  return messages;
}

export default loadLocaleMessages;