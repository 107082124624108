
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import './assets/style/main.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { createI18n } from 'vue-i18n';
import loadLocaleMessages from './i18n/i18n';
import store from './store';

const usersLanguage = store.state.availibleLocales.includes(window.navigator.language) ? window.navigator.language: 'it' ;

export {usersLanguage};

export const i18n = createI18n({
    legacy: false,
    locale: store.state.locale || 'it',
    messages: loadLocaleMessages()
  });

createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .mount("#app");
