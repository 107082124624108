import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "inrecruiting_announces" }

import { onMounted, watch, ref } from 'vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'InrecruitingComponent',
  setup(__props) {

const store = useStore();
const selectedLanguage = ref(store.state.locale);

const loadInRecruitingModule = () => {
    // verifico se la pagina è stata ricaricata
    const hasReloaded = sessionStorage.getItem('inrecruitingReloaded');

    if (!hasReloaded) {
         // imposto il sessionStorage
        sessionStorage.setItem('inrecruitingReloaded', 'true');
        window.location.reload();
    } else {
        const script = document.createElement('script');
        const isProd = process.env.NODE_ENV === 'production';
        const isEnglish = selectedLanguage.value === 'en';

        script.src = isProd
            ? (isEnglish ? process.env.VUE_APP_URL_INRECRUITING_PROD_ENG : process.env.VUE_APP_URL_INRECRUITING_PROD)
            : (isEnglish ? process.env.VUE_APP_URL_INRECRUITING_SVIL_ENG : process.env.VUE_APP_URL_INRECRUITING_SVIL);
        
        script.defer = true;
        document.body.appendChild(script);

        sessionStorage.removeItem('inrecruitingReloaded');
    }
};

onMounted(() => {
    loadInRecruitingModule();
});

// Watch per ricaricare il modulo quando cambia la lingua
watch(selectedLanguage, () => {
    // Rimuovi il vecchio script per evitare conflitti
    const existingScript = document.querySelector('script[src*="inrecruiting"]');
    if (existingScript) {
        existingScript.remove();
    }
    loadInRecruitingModule();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}
}

})