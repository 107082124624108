import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bb-background-section-1 text-light text-center" }
const _hoisted_2 = { class: "container mt-5 p-0" }
const _hoisted_3 = { class: "mt-5" }
const _hoisted_4 = { class: "container h-100" }
const _hoisted_5 = { class: "d-flex h-100 text-center align-items-center" }
const _hoisted_6 = { class: "w-100 text-white" }
const _hoisted_7 = { class: "bb-title" }
const _hoisted_8 = {
  class: "btn btn-lg bb-btn-gradient-green text-uppercase mt-5 rounded-pill mb-5",
  href: "#who-we-are",
  role: "button"
}
const _hoisted_9 = { id: "coockies-bar" }
const _hoisted_10 = {
  class: "bb-backgroud",
  id: "who-we-are"
}
const _hoisted_11 = {
  class: "bb-background-section-2",
  id: "our-customers"
}
const _hoisted_12 = {
  class: "bb-backgroud",
  id: "what-we-do"
}
const _hoisted_13 = {
  class: "bb-background-section-3 p-0",
  id: "differentiates"
}
const _hoisted_14 = {
  class: "bb-backgroud-2",
  id: "technologies"
}
const _hoisted_15 = {
  class: "bb-backgroud",
  id: "contacts"
}

import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ContactsComponent from "../layout/ContactsComponent.vue";
import WhatWeDoComponent from "../layout/WhatWeDoComponent.vue";
import WhoWeAreComponent from "../layout/WhoWeAreComponent.vue";
import OurCustomersComponent from './OurCustomersComponent.vue';
import WhatDifferentiatesUsComponent from "../layout/WhatDifferentiatesUsComponent.vue";
import TechnologiesComponent from './TechnologiesComponent.vue';
import CoockiesBar from '../elements/CoockiesBar.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeComponent',
  setup(__props) {

const route = useRoute();
const router = useRouter();

const sections = ['who-we-are', 'what-we-do', 'contacts'];
let observer: IntersectionObserver;

const scrollToSectionFromQuery = () => {
  const section = route.query.section as string || route.hash.substring(1);
  if (section) {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      router.replace({ path: '/' });
    }
  }
};

const observeSections = () => {
  observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let sectionId = entry.target.id;

          if (sectionId === 'who-we-are') {
            sectionId = 'about';
          }
          if (sectionId === 'what-we-do') {
            sectionId = 'services';
          }
          if (sectionId === 'contacts') {
            sectionId = 'contacts';
          }

          if (window.location.pathname !== `/${sectionId}`) {
            window.history.pushState(null, '', `/${sectionId}`);
          }
        }
      });
    },
    { threshold: 0.3 }
  );

  sections.forEach((sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) observer.observe(sectionElement);
  });
};

onMounted(() => {
    scrollToSectionFromQuery();
    observeSections();
});

return (_ctx: any,_cache: any) => {
  const _component_NavbarComponent = _resolveComponent("NavbarComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavbarComponent),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("header", _hoisted_3, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "overlay" }, null, -1)),
          _cache[1] || (_cache[1] = _createElementVNode("video", {
            autoplay: "",
            loop: "",
            muted: "",
            playsinline: ""
          }, [
            _createElementVNode("source", {
              src: "https://firebasestorage.googleapis.com/v0/b/b4b-website-dev.appspot.com/o/Video%20full%20page%20B4B.mp4?alt=media&token=ed52bd46-f11f-43b8-bb08-4c126ee07cb2",
              type: "video/mp4"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('HOME_TITLE')), 1),
                _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t('HOME_BTN_SHOW_MORE')), 1)
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_9, [
        _createVNode(CoockiesBar)
      ])
    ]),
    _createElementVNode("section", _hoisted_10, [
      _createVNode(WhoWeAreComponent)
    ]),
    _createElementVNode("section", _hoisted_11, [
      _createVNode(OurCustomersComponent)
    ]),
    _createElementVNode("section", _hoisted_12, [
      _createVNode(WhatWeDoComponent)
    ]),
    _createElementVNode("section", _hoisted_13, [
      _createVNode(WhatDifferentiatesUsComponent)
    ]),
    _createElementVNode("section", _hoisted_14, [
      _createVNode(TechnologiesComponent)
    ]),
    _createElementVNode("section", _hoisted_15, [
      _createVNode(ContactsComponent)
    ])
  ], 64))
}
}

})