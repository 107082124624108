import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../assets/images/Raggruppa-pm.svg'
import _imports_1 from '../../assets/images/Raggruppa-o.svg'


const _hoisted_1 = { class: "container pb-5 pt-5" }
const _hoisted_2 = { class: "row pt-5" }
const _hoisted_3 = { class: "col-sm-12 bb-padding mt-5 mb-5" }
const _hoisted_4 = { class: "text-center bb-txt-sec-name mb-4" }
const _hoisted_5 = { class: "text-center bb-txt-sec-title mb-4" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-sm-12 bb-padding" }
const _hoisted_8 = { class: "image-wrapper" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "carousel-indicators" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-sm-12 mt-5 p-0" }
const _hoisted_14 = { class: "text-center bb-txt-sec-title pb-0 pb-lg-5" }
const _hoisted_15 = { class: "row pt-5 pb-0 pb-sm-5" }
const _hoisted_16 = { class: "col-sm-5 align-self-center bb-padding-dx" }
const _hoisted_17 = { class: "text-left bb-txt-sec-title pb-3 bb-txt-margin-custom" }
const _hoisted_18 = { class: "text-left bb-txt-sec-subtitle bb-txt-margin-custom" }
const _hoisted_19 = { class: "row pt-5" }
const _hoisted_20 = { class: "col-sm-5 align-self-center p-0 order-1 order-md-2 bb-padding-lx" }
const _hoisted_21 = { class: "text-end bb-txt-sec-title pb-3 bb-txt-margin-custom-right" }
const _hoisted_22 = { class: "text-end bb-txt-sec-subtitle bb-txt-margin-custom-right" }

import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'WhatWeDoComponent',
  setup(__props) {

const images = ref([
  require('../../assets/images/what-we-do-carousel-1.png'),
  require('../../assets/images/what-we-do-carousel-2.png'),
  require('../../assets/images/what-we-do-carousel-3.png'),
]);

const currentIndex = ref(0);

const prevSlide = () => {
  currentIndex.value = (currentIndex.value - 1 + images.value.length) % images.value.length;
};

const nextSlide = () => {
  currentIndex.value = (currentIndex.value + 1) % images.value.length;
};

// Funzione per andare direttamente a una slide specifica
const goToSlide = (index: number) => {
  currentIndex.value = index;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t('WHAT_WE_DO_TITLE')), 1),
        _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('WHAT_WE_DO_SUBTITLE')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("img", {
            src: images.value[currentIndex.value],
            class: "img-fluid",
            alt: "What we do"
          }, null, 8, _hoisted_9),
          _createElementVNode("div", { class: "carousel-controls" }, [
            _createElementVNode("button", {
              class: "carousel-btn prev-btn",
              onClick: prevSlide
            }, _cache[0] || (_cache[0] = [
              _createElementVNode("span", {
                class: "carousel-control-prev-icon",
                "aria-hidden": "true"
              }, null, -1)
            ])),
            _createElementVNode("button", {
              class: "carousel-btn next-btn",
              onClick: nextSlide
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("span", {
                class: "carousel-control-next-icon",
                "aria-hidden": "true"
              }, null, -1)
            ]))
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(images.value, (image, index) => {
            return (_openBlock(), _createElementBlock("span", {
              key: index,
              class: _normalizeClass(["dot", { active: index === currentIndex.value }]),
              onClick: ($event: any) => (goToSlide(index))
            }, null, 10, _hoisted_11))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("h3", _hoisted_14, _toDisplayString(_ctx.$t('WHAT_WE_DO_SERVICES')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("h4", _hoisted_17, _toDisplayString(_ctx.$t('WHAT_WE_DO_PM')), 1),
        _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t('WHAT_WE_DO_PROJECT')), 1)
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-sm-7 p-0 align-self-center bb-p-cell" }, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "img-fluid float-end",
          alt: "Definition"
        })
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_19, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-sm-7 p-0 order-2 order-md-1 align-self-center bb-p-cell-r" }, [
        _createElementVNode("img", {
          src: _imports_1,
          class: "img-fluid",
          alt: "Definition"
        })
      ], -1)),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "pt-5" }, null, -1)),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("h4", _hoisted_21, _toDisplayString(_ctx.$t('WHAT_WE_DO_OUTSOURCING')), 1),
        _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t('WHAT_WE_DO_EST')), 1)
      ])
    ])
  ]))
}
}

})