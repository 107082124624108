import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/work-with-us.png'


const _hoisted_1 = { class: "container-xxl bb-backgroud" }
const _hoisted_2 = { class: "row pt-5 pt-custom" }
const _hoisted_3 = { class: "col-sm-5 align-self-center bb-padding pb-4" }
const _hoisted_4 = { class: "bb-txt-sec-name mb-4 mt-5" }
const _hoisted_5 = { class: "bb-txt-sec-title mb-4" }
const _hoisted_6 = { class: "bb-txt-sec-subtitle" }
const _hoisted_7 = { class: "row bb-white pt-5" }
const _hoisted_8 = { class: "col-sm-12 bb-padding" }
const _hoisted_9 = { class: "bb-txt-sec-name mb-4 txt-c" }
const _hoisted_10 = { class: "bb-txt-sec-title mb-5 txt-c" }
const _hoisted_11 = { class: "col-sm-12 pb-5 bb-padding" }
const _hoisted_12 = { class: "bb-padding-custom" }

import InrecruitingComponent from '../elements/InrecruitingComponent.vue';

import { onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'WorkWithUsComponent',
  setup(__props) {

onMounted(() =>{
 window.scrollTo(0,0);
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t('WORK_WITH_US_TITLE')), 1),
        _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('WORK_WITH_US_CV')), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('WORK_WITH_US_SUBTITLE')), 1)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-sm-7 align-self-center bb-padding" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Lavoro",
          class: "img-fluid rounded mx-auto d-block"
        })
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("h4", _hoisted_9, _toDisplayString(_ctx.$t('WORK_WITH_US_POS_OPEN')), 1),
        _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$t('WORK_WITH_US_SEARCH')), 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(InrecruitingComponent)
        ])
      ])
    ])
  ]))
}
}

})