<template>
    <div id="inrecruiting_announces"></div>
</template>
  
<script setup lang="ts">
import { onMounted, watch, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const selectedLanguage = ref(store.state.locale);

const loadInRecruitingModule = () => {
    // verifico se la pagina è stata ricaricata
    const hasReloaded = sessionStorage.getItem('inrecruitingReloaded');

    if (!hasReloaded) {
         // imposto il sessionStorage
        sessionStorage.setItem('inrecruitingReloaded', 'true');
        window.location.reload();
    } else {
        const script = document.createElement('script');
        const isProd = process.env.NODE_ENV === 'production';
        const isEnglish = selectedLanguage.value === 'en';

        script.src = isProd
            ? (isEnglish ? process.env.VUE_APP_URL_INRECRUITING_PROD_ENG : process.env.VUE_APP_URL_INRECRUITING_PROD)
            : (isEnglish ? process.env.VUE_APP_URL_INRECRUITING_SVIL_ENG : process.env.VUE_APP_URL_INRECRUITING_SVIL);
        
        script.defer = true;
        document.body.appendChild(script);

        sessionStorage.removeItem('inrecruitingReloaded');
    }
};

onMounted(() => {
    loadInRecruitingModule();
});

// Watch per ricaricare il modulo quando cambia la lingua
watch(selectedLanguage, () => {
    // Rimuovi il vecchio script per evitare conflitti
    const existingScript = document.querySelector('script[src*="inrecruiting"]');
    if (existingScript) {
        existingScript.remove();
    }
    loadInRecruitingModule();
});

</script>
  
<style scoped lang="scss">

</style>
